import { createServer } from "miragejs";
import appConfig from "configs/app.config";
import { notificationListData, searchQueryPoolData } from "./data/commonData";
import {
  projectList,
  scrumboardData,
  issueData,
  projectDashboardData,
} from "./data/projectData";
import { usersData, userDetailData } from "./data/usersData";
import { eventsData, mailData, crmDashboardData } from "./data/crmData";
import {
  productsData,
  ordersData,
  orderDetailsData,
  salesDashboardData,
} from "./data/salesData";
import {
  portfolioData,
  walletsData,
  marketData,
  transactionHistoryData,
  cryptoDashboardData,
} from "./data/cryptoData";
import {
  settingData,
  settingIntergrationData,
  settingBillingData,
  invoiceData,
  logData,
  accountFormData,
} from "./data/accountData";
import {
  helpCenterCategoriesData,
  helpCenterArticleListData,
} from "./data/knowledgeBaseData";
import { signInUserData } from "./data/authData";

import {
  commonFakeApi,
  projectFakeApi,
  crmFakeApi,
  salesFakeApi,
  accountFakeApi,
  cryptoFakeApi,
  authFakeApi,
  knowledgeBaseFakeApi,
} from "./fakeApi";

const { apiPrefix } = appConfig;

export default function mockServer({ environment = "test" }) {
  return createServer({
    environment,
    seeds(server) {
      server.db.loadData({
        notificationListData,
        searchQueryPoolData,
        projectList,
        scrumboardData,
        issueData,
        usersData,
        userDetailData,
        eventsData,
        mailData,
        productsData,
        ordersData,
        orderDetailsData,
        settingData,
        settingIntergrationData,
        settingBillingData,
        invoiceData,
        logData,
        accountFormData,
        portfolioData,
        walletsData,
        marketData,
        transactionHistoryData,
        helpCenterCategoriesData,
        helpCenterArticleListData,
        signInUserData,
        salesDashboardData,
        crmDashboardData,
        projectDashboardData,
        cryptoDashboardData,
      });
    },
    routes() {
      this.urlPrefix = "";
      this.namespace = "";
      this.passthrough((request) => {
        let isExternal =
          request.url.endsWith("settings") ||
          request.url.endsWith("add_child_program") ||
          request.url.endsWith("manage_program") ||
          request.url.endsWith("settings") ||
          request.url.endsWith("add_setting") ||
          request.url.endsWith("update_setting") ||
          request.url.endsWith("add_emailset") ||
          request.url.endsWith("email") ||
          request.url.endsWith("getfieldoptions") ||
          request.url.endsWith("login") ||
          request.url.endsWith("remove_designation") ||
          request.url.endsWith("get_edit_designation") ||
          request.url.endsWith("edit_designation") ||
          request.url.endsWith("donations") ||
          request.url.endsWith("form_fields") ||
          request.url.endsWith("get_view_donations") ||
          request.url.endsWith("view_donations") ||
          request.url.endsWith("get_multi_program_select") ||
          request.url.endsWith("multi_program_select") ||
          request.url.endsWith("all_donors") ||
          request.url.endsWith("get_add_donor") ||
          request.url.endsWith("add_donor") ||
          request.url.endsWith("show_all_donors_table") ||
          request.url.endsWith("show_all_donors_ajax") ||
          request.url.endsWith("archive_donors") ||
          request.url.endsWith("show_all_entities_table") ||
          request.url.endsWith("add_entity") ||
          request.url.endsWith("field_options") ||
          request.url.endsWith("show_all_entities_ajax") ||
          request.url.endsWith("remove_saved_report") ||
          request.url.endsWith("remove_entities") ||
          request.url.endsWith("edit_entity") ||
          request.url.endsWith("get_edit_entity") ||
          request.url.endsWith("recordUpload") ||
          request.url.endsWith("get_move_entity") ||
          request.url.endsWith("move_entity") ||
          request.url.endsWith("add_note") ||
          request.url.endsWith("remove_entity") ||
          request.url.endsWith("activate_entity") ||
          request.url.endsWith("delete_entity") ||
          request.url.endsWith("notes") ||
          request.url.endsWith("get_edit_note") ||
          request.url.endsWith("edit_note") ||
          request.url.endsWith("select_saved_report") ||
          request.url.endsWith("show_all_sponsorships_ajax") ||
          request.url.endsWith("show_all_sponsorships_table") ||
          request.url.endsWith("move_entity") ||
          request.url.endsWith("add_note") ||
          request.url.endsWith("remove_entity") ||
          request.url.endsWith("activate_entity") ||
          request.url.endsWith("delete_entity") ||
          request.url.endsWith("notes") ||
          request.url.endsWith("get_edit_note") ||
          request.url.endsWith("edit_note") ||
          request.url.endsWith("get_donor_field_options") ||
          request.url.endsWith("donor_field_options") ||
          request.url.endsWith("remove_donor_saved_report") ||
          request.url.endsWith("get_edit_donor") ||
          request.url.endsWith("edit_donor") ||
          request.url.endsWith("get_sponsorships") ||
          request.url.endsWith("sponsorships") ||
          request.url.endsWith("add_sponsorships") ||
          request.url.endsWith("remove_sponsorship") ||
          request.url.endsWith("donations_by_donor") ||
          request.url.endsWith("get_edit_donation") ||
          request.url.endsWith("edit_donation") ||
          request.url.endsWith("get_commitment_donation") ||
          request.url.endsWith("commitment_donation") ||
          request.url.endsWith("get_edit_commitment") ||
          request.url.endsWith("send_new_donor_email") ||
          request.url.endsWith("select_saved_report") ||
          request.url.endsWith("send_notify_donors") ||
          request.url.endsWith("send_year_end_donors") ||
          request.url.endsWith("activate_donors") ||
          request.url.endsWith("get_edit_emailtemplate") ||
          request.url.endsWith("edit_emailtemplate") ||
          request.url.endsWith("email_manager") ||
          request.url.endsWith("view_email") ||
          request.url.endsWith("assign_admin") ||
          request.url.endsWith("update_email_status") ||
          request.url.endsWith("send_email_response") ||
          request.url.endsWith("admin") ||
          request.url.endsWith("reload_stats") ||
          request.url.endsWith("get_client_account") ||
          request.url.endsWith("edit_client_account") ||
          request.url.endsWith("get_email_settings") ||
          request.url.endsWith("email_settings") ||
          request.url.endsWith("get_template") ||
          request.url.endsWith("template") ||
          request.url.endsWith("get_add_admin") ||
          request.url.endsWith("add_admin") ||
          request.url.endsWith("view_admins") ||
          request.url.endsWith("get_edit_admin") ||
          request.url.endsWith("edit_admin") ||
          request.url.endsWith("remove_admin") ||
          request.url.endsWith("client_cc") ||
          request.url.endsWith("update_client_cc") ||
          request.url.endsWith("reset-password") ||
          request.url.endsWith("verification-notification") ||
          request.url.endsWith("remind") ||
          request.url.endsWith("verify") ||
          request.url.endsWith("add_note") ||
          request.url.endsWith("show_all_sponsorships_ajax/all") ||
          request.url.endsWith("send_message") ||
          request.url.endsWith("add_child_program") ||
          request.url.endsWith("add_sub_program") ||
          request.url.endsWith("program_settings") ||
          request.url.endsWith("edit_program") ||
          request.url.endsWith("add_settings_to_program") ||
          request.url.endsWith("add_sponsorship_form_to_program") ||
          request.url.endsWith("add_donor_form_to_program") ||
          request.url.endsWith("add_emailsets_to_program") ||
          request.url.endsWith("remove_program") ||
          request.url.endsWith("forms") ||
          request.url.endsWith("create_form") ||
          request.url.endsWith("get_edit_form") ||
          request.url.endsWith("edit_form") ||
          request.url.endsWith("remove_form") ||
          request.url.endsWith("manage_form") ||
          request.url.endsWith("get_add_form_field") ||
          request.url.endsWith("add_form_field") ||
          request.url.endsWith("edit_form_field") ||
          request.url.endsWith("delete_form_field") ||
          request.url.endsWith("add_submit_form_to_program") ||
          request.url.endsWith("view_groups") ||
          request.url.endsWith("create_group") ||
          request.url.endsWith("get_edit_group")||
          request.url.endsWith("remove_group")||
          request.url.endsWith("edit_group")||
          request.url.endsWith("view_all")
           
        return isExternal;
      });
      this.passthrough();

      commonFakeApi(this, apiPrefix);
      projectFakeApi(this, apiPrefix);
      crmFakeApi(this, apiPrefix);
      salesFakeApi(this, apiPrefix);
      accountFakeApi(this, apiPrefix);
      authFakeApi(this, apiPrefix);
      cryptoFakeApi(this, apiPrefix);
      knowledgeBaseFakeApi(this, apiPrefix);
    },
  });
}
