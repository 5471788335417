import ApiService from "./ApiService";
import ProgramApiService from "./ProgramApiService";

export async function apiGetProgramList(data) {
  return ApiService.fetchData({
    url: "/programs",
    method: "get",
    data,
  });
}

export async function apiPostCSVProcess(data, program_id) {
  return ApiService.fetchData({
    url: `/csv_process/${program_id}`,
    method: "post",
    data,
  });
}

export async function apiPostRelationshipCSVProcess(data, program_id) {
  return ApiService.fetchData({
    url: `/csv_process/${program_id}/relationships`,
    method: "post",
    data,
  });
}

export async function apiPostPaymentCSVProcess(data, program_id) {
  return ApiService.fetchData({
    url: `/csv_process/${program_id}/payments`,
    method: "post",
    data,
  });
}

export async function apiGetSendMessage(data) {
  return ApiService.fetchData({
    url: `/admin/message/send/${data.entity_id}/${data.donor_id}/${data.from_title}`,
    method: "get",
  });
}

export async function apiPostEditFile(data) {
  return ApiService.fetchData({
    url: `/file/${data.id}`,
    method: "put",
    data,
  });
}

export async function apiPostUploadFile(data) {
  return ApiService.fetchData({
    url: "/admin/recordUpload",
    method: "post",
    data,
  });
}

export async function apiMakeProfile(data) {
  return ApiService.fetchData({
    url: `/picture/${data.id}/profile`,
    method: "get",
  });
}

export async function apiGetDeleteFile(data) {
  return ApiService.fetchData({
    url: `/file/${data.id}`,
    method: "delete",
  });
}

export async function apiGetSettingsList(data) {
  return ApiService.fetchData({
    url: "/admin/settings",
    method: "get",
  });
}

export async function apiGetEditSettingsList(data) {
  return ApiService.fetchData({
    url: `/admin/settings/${data.settings_id}`,
    method: "get",
  });
}

export async function apiGetCampaignEditSettingsList(data) {
  return ApiService.fetchData({
    url: `/admin/campaign/settings/${data.settings_id}`,
    method: "get",
  });
}

export async function apiCreateProgramSettings(data) {
  return ApiService.fetchData({
    url: "/admin/settings",
    method: "post",
    data,
  });
}

export async function apiDeleteProgramsSettings(data) {
  return ApiService.fetchData({
    url: `/admin/settings/${data.settings_id}`,
    method: "delete",
  });
}

export async function apiUpdateSetting(data) {
  return ApiService.fetchData({
    url: `/admin/settings/${data.id}`,
    method: "put",
    data,
  });
}

export async function apiCreateRecipient(data) {
  return ApiService.fetchData({
    url: `/admin/entity/${data.program_id}`,
    method: "post",
    data,
  });
}

export async function addAutoEmail(data) {
  return ApiService.fetchData({
    url: "/admin/auto_emails/emailset",
    method: "post",
    data,
  });
}

export async function apiGetEmailList(data) {
  return ApiService.fetchData({
    url: "/admin/auto_emails",
    method: "get",
  });
}

export async function apiDeleteAutoEmailList(data) {
  return ApiService.fetchData({
    url: `/admin/auto_emails/emailset/${data.emailset_id}`,
    method: "delete",
  });
}

export async function apiGetEmailTemplate(data) {
  return ApiService.fetchData({
    url: `/admin/auto_emails/template/${data.emailset_id}/${data.trigger}`,
    method: "get",
    data,
  });
}

export async function apiPostEditEmailTemplate(data) {
  return ApiService.fetchData({
    url: "/admin/auto_emails/template",
    method: "put",
    data,
  });
}
export async function apiGetEntitiesList(params) {
  return ApiService.fetchData({
    url: `/admin/entities/${params.program_id}`,
    method: "get",
    params,
  });
}

export async function apiGetDefaultFieldOptionsList(data) {
  return ApiService.fetchData({
    url: `admin/entities/${data.program_id}/headers`,
    method: "get",
    data,
  });
}

export async function apiGetFieldOptionsList(data) {
  return ApiService.fetchData({
    url: `/admin/fields/options/${data.program_id}/${data.type}`,
    method: "get",
    data,
  });
}

export async function apiGetAddDesignationData(data) {
  return ApiService.fetchData({
    url: "/admin/designation",
    method: "get",
  });
}

export async function apiGetRecipientFields(data) {
  return ApiService.fetchData({
    url: `/admin/entity/${data.program_id}`,
    method: "get",
  });
}

export async function apiEditReport(data, params) {
  return ApiService.fetchData({
    url: `/admin/fields/options/${params.program_id}`,
    method: "post",
    data,
  });
}

export async function apiCreateDesignation(data) {
  return ApiService.fetchData({
    url: "/admin/designation",
    method: "post",
    data,
  });
}

export async function apiDeleteReport(data) {
  return ApiService.fetchData({
    url: `/admin/view/${data.report_id}/${data.program_id}`,
    method: "delete",
  });
}
export async function apiGetDesignations(data) {
  return ApiService.fetchData({
    url: "/admin/designations",
    method: "get",
  });
}

export async function apiDeleteDesignation(data) {
  return ApiService.fetchData({
    url: `admin/designation/${data.designation_id}`,
    method: "delete",
  });
}

export async function apiArchiveProgram(data) {
  return ApiService.fetchData({
    url: `/admin/entities/${data.program_id}`,
    method: "delete",
    data,
  });
}

export async function apiGetEditDesignation(data) {
  return ApiService.fetchData({
    url: `/admin/designation/${data.designation_id}`,
    method: "get",
  });
}

export async function apiUpdateDesignation(data) {
  return ApiService.fetchData({
    url: `/admin/designation/${data.designation_id}`,
    method: "put",
    data,
  });
}

export async function apiGetMultiPrograms(data) {
  return ApiService.fetchData({
    url: "/admin/multi_program_select",
    method: "get",
    data,
  });
}

export async function apiGetEditEntityList(data) {
  return ApiService.fetchData({
    url: `admin/program/entities/${data.id}`,
    method: "get",
    data,
  });
}

export async function apiMakeURL(data) {
  return ApiService.fetchData({
    url: "/admin/multi_program_select",
    method: "post",
    data,
  });
}

export async function apiUpdateEntity(data) {
  return ApiService.fetchData({
    url: "/admin/program/entity",
    method: "patch",
    data,
  });
}

export async function apiUpdateEntities(data) {
  return ApiService.fetchData({
    url: "/admin/program/entities",
    method: "patch",
    data,
  });
}

export async function apiSendRecipientMessage(data) {
  return ApiService.fetchData({
    url: `/admin/message/send/${data.entity_id}/${data.from_title}`,
    method: "post",
    data,
  });
}

export async function apiApproveDraftMessage(data) {
  return ApiService.fetchData({
    url: `/admin/message/approve/draft/${data.email_id}`,
    method: "post",
    data,
  });
}

export async function apiBulkApproveDraftMessages(data) {
  return ApiService.fetchData({
    url: `/admin/message/drafts/approve`,
    method: "post",
    data,
  });
}

export async function apiDeleteDraftMessage(data) {
  return ApiService.fetchData({
    url: `/admin/messages/drafts/delete`,
    method: "delete",
    data,
  });
}

export async function apiApproveDraftResponse(data) {
  return ApiService.fetchData({
    url: `/admin/message/draft/response/${data.email_id}/approve`,
    method: "put",
    data,
  });
}

export async function apiDraftRecipientMessage(data) {
  return ApiService.fetchData({
    url: `/admin/message/draft/${data.entity_id}/${data.from_title}`,
    method: "post",
    data,
  });
}

export async function apiEditDraftMessage(data) {
  return ApiService.fetchData({
    url: `/admin/message/draft/${data.email_id}/edit`,
    method: "patch",
    data,
  });
}

export async function apiPostNotes(data) {
  return ApiService.fetchData({
    url: `/admin/notes/${data.type}/${data.program_id}`,
    method: "post",
    data,
  });
}

export async function apiUploadFile(data) {
  return ApiService.fetchData({
    url: `/file/${data.type}/${data.id}/upload`,
    method: "get",
    data,
  });
}

export async function apiGetCSVImport(program_id) {
  return ApiService.fetchData({
    url: `/csv_import/${program_id}`,
    method: "get",
  });
}

export async function apiPostCSVImport(data, program_id) {
  return ProgramApiService.fetchData({
    url: `/csv_import/${program_id}`,
    method: "post",
    data,
  });
}

export async function apiGetFilesTable(data) {
  return ApiService.fetchData({
    url: `/files/${data.type}/${data.id}/table`,
    method: "get",
    data,
  });
}

export async function apiChangeProgram(data) {
  return ApiService.fetchData({
    url: `/admin/move/${data.entity_id}`,
    method: "post",
    data,
  });
}

export async function apiChangeManyPrograms(data) {
  return ApiService.fetchData({
    url: `/admin/entities/move`,
    method: "put",
    data,
  });
}

export async function apiGetMoveEntityFields(data) {
  return ApiService.fetchData({
    url: `/admin/move/${data.entity_id}/compatible_programs`,
    method: "get",
  });
}

export async function apiArchiveEntity(data) {
  return ApiService.fetchData({
    url: `/admin/entity/${data.entity_id}/delete`,
    method: "delete",
  });
}

export async function apiRestoreEntity(data) {
  return ApiService.fetchData({
    url: `/admin/entity/${data.entity_id}/activate`,
    method: "get",
  });
}

export async function apiRestoreEntities(data) {
  return ApiService.fetchData({
    url: `/admin/entities/${data.program_id}/activate`,
    method: "post",
    data,
  });
}

export async function apiDeleteEntity(data) {
  return ApiService.fetchData({
    url: `/admin/entity/${data.entity_id}`,
    method: "delete",
  });
}

export async function apiDeleteEntities(data) {
  return ApiService.fetchData({
    url: `/admin/entities/${data.program_id}/delete`,
    method: "delete",
    data,
  });
}

export async function apiGetNotes(data) {
  return ApiService.fetchData({
    url: "/admin/notes",
    method: "post",
    data,
  });
}

export async function apiGetNote(data) {
  return ApiService.fetchData({
    url: `/admin/notes/${data.note_id}/${data.program_id}`,
    method: "get",
  });
}

export async function apiUpdateNote(data) {
  return ApiService.fetchData({
    url: `/admin/notes/${data.note_id}/${data.program_id}`,
    method: "put",
    data,
  });
}

export async function apiGetReports(data) {
  return ApiService.fetchData({
    url: "/admin/select_saved_report",
    method: "post",
    data,
  });
}

export async function apiCreateProgram(data) {
  return ApiService.fetchData({
    url: "/admin/program",
    method: "post",
    data,
  });
}

export async function apiCreateSubProgram(data) {
  return ApiService.fetchData({
    url: "/admin/program/sub_program",
    method: "post",
    data,
  });
}

export async function apiGetEditProgramData(data) {
  return ApiService.fetchData({
    url: `/program/${data.id}/settings`,
    method: "get",
    data,
  });
}

export async function apiUpdateProgram(data) {
  return ApiService.fetchData({
    url: `/program/${data.program_id}`,
    method: "put",
    data,
  });
}

export async function apiUpdateAttachProgramSettings(data) {
  return ApiService.fetchData({
    url: "/admin/program/settings",
    method: "post",
    data,
  });
}

export async function apiUpdateSponsorshipFormToProgram(data) {
  return ApiService.fetchData({
    url: "/admin/program/form/sponsorship",
    method: "post",
    data,
  });
}

export async function apiUpdateDonorFormToProgram(data) {
  return ApiService.fetchData({
    url: "/admin/program/form/donor",
    method: "post",
    data,
  });
}

export async function apiUpdateEmailSetsToProgram(data) {
  return ApiService.fetchData({
    url: "/admin/program/emailset",
    method: "post",
    data,
  });
}

export async function apiDeleteProgram(data) {
  return ApiService.fetchData({
    url: `/admin/program/${data.program_id}`,
    method: "delete",
  });
}

export async function apiGetFormList(data) {
  return ApiService.fetchData({
    url: "/admin/forms",
    method: "get",
  });
}

export async function apiCreateForm(data) {
  return ApiService.fetchData({
    url: "/admin/forms",
    method: "post",
    data,
  });
}

export async function apiGetEditFormData(data) {
  return ApiService.fetchData({
    url: `/admin/forms/${data.hysform_id}`,
    method: "get",
  });
}

export async function apiUpdateForm(data) {
  return ApiService.fetchData({
    url: `/admin/forms/${data.hysform_id}`,
    method: "put",
    data,
  });
}

export async function apiDeleteForm(data) {
  return ApiService.fetchData({
    url: `/admin/forms/${data.hysform_id}`,
    method: "delete",
  });
}

export async function apiGetManageFormList(data) {
  return ApiService.fetchData({
    url: `/admin/forms/${data.hysform_id}/manage`,
    method: "get",
  });
}

export async function apiGetUpdateFieldsOrder(params) {
  return ApiService.fetchData({
    url: `/admin/form/fields/order/${params.type}`,
    method: "get",
    params,
  });
}

export async function apiGetFormFields(data) {
  return ApiService.fetchData({
    url: `/admin/form/field/${data.hysform_id}/${data.type}`,
    method: "get",
  });
}

export async function apiCreateFormField(data) {
  return ApiService.fetchData({
    url: `/admin/form/field/${data.hysform_id}/${data.type}`,
    method: "post",
    data,
  });
}

export async function apiUpdateFormFields(data) {
  return ApiService.fetchData({
    url: "/admin/form/field",
    method: "put",
    data,
  });
}

export async function apiDeleteFormField(data) {
  return ApiService.fetchData({
    url: "/admin/form/field",
    method: "delete",
    data,
  });
}

export async function apiUpdateFormToProgram(data) {
  return ApiService.fetchData({
    url: "/admin/program/form",
    method: "post",
    data,
  });
}

export async function apiGetSavedReport(data) {
  return ApiService.fetchData({
    url: `/admin/programs/view/${data.report_id}/${data.program_id}`,
    method: "get",
  });
}

export async function apiGetRotateImage(data) {
  return ApiService.fetchData({
    url: `/picture/${data.id}/rotate`,
    method: "get",
  });
}

export async function apiSendEmail(data) {
  return ApiService.fetchData({
    url: `admin/auto_emails/send/${data.program_id}/${data.trigger}/${data.to}/${data.donor_id}/${data.entity_id}`,
    method: "get",
    data,
  });
}

export async function apiRedirectToFrontendRandomEntity(data) {
  return ApiService.fetchData({
    url: `frontend/entities/random/${data.client_id}/${data.program_id}`,
    method: "get",
    data,
  });
}

export async function apiGetSettingsAddData(data) {
  return ApiService.fetchData({
    url: "/admin/add_settings",
    method: "get",
  });
}

export async function apiGetProgramStatistics(params) {
  return ApiService.fetchData({
    url: `/admin/program/${params.program_id}/statistics`,
    method: "get",
    params,
  });
}

export async function apiGetProgramDetails(data) {
  return ApiService.fetchData({
    url: `/program/${data}`,
    method: "get",
  });
}

export async function apiGetCampaignProgramDetails(data) {
  return ApiService.fetchData({
    url: `/program/${data}/campaign`,
    method: "get",
  });
}

export async function apiGetArchivedReports(data) {
  return ApiService.fetchData({
    url: `/admin/list_archived_forms/${data.type}/${data.Id}/${data.hysformId}`,
    method: "get",
  });
}

export async function apiGetSubmitForm(data) {
  return ApiService.fetchData({
    url: `/admin/submit_form/${data.type}/${data.id}/${data.program_id}/${data.form_id}`,
    method: "get",
  });
}

export async function apiPostSubmitForm(data) {
  return ApiService.fetchData({
    url: `/admin/submit_form/${data.type}/${data.id}/${data.program_id}/${data.form_id}`,
    method: "post",
    data,
  });
}

export async function apiGetArchivedForm(data) {
  return ApiService.fetchData({
    url: `/admin/view_archived_form/${data}`,
    method: "get",
  });
}

export async function apiGetEditArchivedForm(data) {
  return ApiService.fetchData({
    url: `/admin/edit_archived_form/${data}`,
    method: "get",
  });
}

export async function apiPostEditArchivedForm(data) {
  return ApiService.fetchData({
    url: `/admin/edit_archived_form/${data.id}`,
    method: "post",
    data,
  });
}

export async function apiDeleteSubmitForm(data, type, program_id) {
  return ApiService.fetchData({
    url: `/admin/remove_submit_form/${type}/${program_id}/${data.id}`,
    method: "get",
  });
}
export async function apiGetDonorActivityLogs(data) {
  return ApiService.fetchData({
    url: `/admin/donor-activities/${data.donor_id}`,
    method: "get",
  });
}
