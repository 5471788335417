import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetEditEntityList, apiUpdateEntity,apiDeleteProgramsSettings } from "services/ProgramsService";

export const getEntity = createAsyncThunk('entityEdit/data/getEntities', async (data) => {
    const response = await apiGetEditEntityList(data)
    return response.data
})

export const updateEntity = async (data) => {
    const response = await apiUpdateEntity(data)
    return response.data
}

export const deleteEntity = async (data) => {
    const response = await apiDeleteProgramsSettings(data)
    return response.data
}

const dataSlice = createSlice({
    name: 'entitiesEdit/data',
    initialState: {
        entityEditloading: false,
        entityData: [],
        
    },
    reducers: {
    },
    extraReducers: {
        [getEntity.fulfilled]: (state, action) => {
            state.entityData = action.payload
            state.entityEditloading = false
        },
        [getEntity.pending]: (state) => {
            state.entityEditloading = true
        },
    }
})

export default dataSlice.reducer
