import ApiService from "./ApiService";

export async function apiGetAccountSettingData(data) {
  return ApiService.fetchData({
    url: "/integrations",
    method: "get",
    data,
  });
}

export async function apiPostAccountSettingData(data) {
  return ApiService.fetchData({
    url: `/integrations/${data.name}`,
    method: "post",
    data,
  });
}

export async function apiClientStatusToggle(data) {
  return ApiService.fetchData({
    url: `/super_admin/client/toggle_status`,
    method: "put",
    data,
  });
}

export async function apiClientLogOut(data) {
  return ApiService.fetchData({
    url: `/super_admin/clients/${data.client_id}/logout`,
    method: "get",
  });
}

export async function apiPostAccountFieldsData(data) {
  return ApiService.fetchData({
    url: "/client",
    method: "put",
    data,
  });
}

export async function apiGetEmailSettingData(data) {
  return ApiService.fetchData({
    url: "/admin/email/settings",
    method: "get",
  });
}

export async function apiPostEmailSettingData(data) {
  return ApiService.fetchData({
    url: "/admin/email/settings",
    method: "post",
    data,
  });
}

export async function apiGetEditTemplateData(data) {
  return ApiService.fetchData({
    url: "/admin/template",
    method: "get",
  });
}

export async function apiPostEditTemplateData(data) {
  return ApiService.fetchData({
    url: "/admin/template",
    method: "post",
    data,
  });
}

export async function apiGetCardData(data) {
  return ApiService.fetchData({
    url: "/client/credit-card",
    method: "get",
    data,
  });
}

export async function apiGetClientPaymentData() {
  return ApiService.fetchData({
    url: "/client/payments",
    method: "get",
  });
}

export async function apiPutUpdateCard(data) {
  return ApiService.fetchData({
    url: "/client/credit-card",
    method: "post",
    data,
  });
}

export async function apiGetAccountSettingIntegrationData() {
  return ApiService.fetchData({
    url: "/account/setting/integration",
    method: "get",
  });
}

export async function apiGetAccountSettingBillingData() {
  return ApiService.fetchData({
    url: "/account/setting/billing",
    method: "get",
  });
}

export async function apiGetAccountInvoiceData(params) {
  return ApiService.fetchData({
    url: "/account/invoice",
    method: "get",
    params,
  });
}

export async function apiGetAccountLogData(data) {
  return ApiService.fetchData({
    url: "/account/log",
    method: "post",
    data,
  });
}

export async function apiGetAccountFormData() {
  return ApiService.fetchData({
    url: "/account/form",
    method: "get",
  });
}

export async function apiGetPriceToPay(data) {
  return ApiService.fetchData({
    url: `/client/${data}/price`,
    method: "get",
  });
}

export async function apiGetIntegrations() {
  return ApiService.fetchData({
    url: "/integrations",
    method: "get",
  });
}

export async function apiGetStripeIntegrationData() {
  return ApiService.fetchData({
    url: "/integrations/stripe",
    method: "get",
  });
}

export async function apiGetStripeConnectResponse(params) {
  return ApiService.fetchData({
    url: `/connect/${params.token}`,
    method: "get",
  });
}

export async function apiGetStripeConnectClientSecret() {
  return ApiService.fetchData({
    url: "/account_session",
    method: "get",
  });
}

export async function apiGetAdmin(params) {
  return ApiService.fetchData({
    url: "/admin/super_admin/clients",
    method: "get",
    params,
  });
}

export async function apiGetStripeConnectRedirectLink(params) {
  return ApiService.fetchData({
    url: `/stripe/${params.client_id}`,
    method: "get",
  });
}

export async function apiToggleClientArchive(data) {
  return ApiService.fetchData({
    url: "/admin/clients/archive",
    method: "post",
    data,
  });
}


export async function apiPostMailGunLogs(data) {
  return ApiService.fetchData({
    url: "/admin/mailgun_logs_data",
    method: "post",
    data,
  });
}
