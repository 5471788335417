import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { boot as bootIntercom, shutdown as shutdownIntercom } from "intercom";
import { apiSignIn, apiSignOut } from "services/AuthService";
import { apiPostDonorLogin } from "services/FrontendServices";
import { onSignInSuccess, onSignOutSuccess, setPermission } from "store/auth/sessionSlice";
import { initialState, setUser } from "store/auth/userSlice";
import { setDonor } from "views/frontend/store/auth/userSlice";

import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const DonorSign = async ({
    username,
    password,
    client_id,
    program_id,
    sessionid,
  }) => {
    //for the donor signin
    try {
      const response = await apiPostDonorLogin(
        { client_id, program_id, sessionid },
        { username, password }
      );

      if (response.data.donor) {
        dispatch(
          setDonor(
            mutateDonor(response.data.donor) || {
              avatar: "",
              userName: "",
              email: "",
            }
          )
        );
      }

      if (response.data.status === "success") {
        localStorage.setItem(
          "SESSION_LOGGED_IN",
          response.data.session_logged_in
        );

        return {
          status: response.data.status,
          message: response.data.message,
          donor: response.data.donor,
          client: response.data.client,
          redirect: response.data.redirect,
        };
      } else {
        return {
          status: response.data.status,
          message: response.data.message,
        };
      }
    } catch (error) {
      //handle error
      return {
        status: error?.response?.data?.status || "error",
        message: error?.response?.data?.message || "An error occurred.",
        userId: error?.response?.data?.user_id || "",
      };
    }
  };

  const extractPermissions = (permissions) => {
    return Object.entries(permissions)
      .filter(([key, value]) => {
        if (Array.isArray(value)) {
          return value.includes("1");
        } else {
          return value === "1";
        }
      })
      .map(([key, value]) => key);
  };

  const signIn = async ({ email, password }) => {
    try {
      const resp = await apiSignIn({ email, password });
      if (resp.data) {
        //check if the user doesnot have an account on beta
        switch (resp.data.status) {
          case "error":
            return {
              status: "error",
              message: resp.data.message,
            };
          case "success":
            if (resp.data.user.email_verified_at !== null) {
              return {
                status: "success",
                message: "success",
              };
            }
            break;
          case "failed":
            // handle pending case
            return {
              status: "failed",
              message: resp.data.message,
            };
          default:
            // handle other cases
            break;
        }
        localStorage.setItem("CLIENT_ID", resp.data.user.client_id);
        localStorage.setItem("PERMISSION", resp.data.permissions);
        localStorage.setItem("USER_ID", resp.data.user.id);
        localStorage.setItem("AUTH_TOKEN", resp.data.token);
        localStorage.setItem("ORGANIZATION_NAME", resp.data.organization);
        localStorage.setItem("PLAN", resp.data.plan);
        localStorage.setItem("ORG_CREATED_AT", resp.data.org_created_at);
        localStorage.setItem("LAST_PAYMENT_DATE", resp.data.last_payment_date);
        localStorage.setItem("LAST_PAYMENT_AMOUNT", resp.data.last_payment_amount);
        localStorage.setItem("MONTHLY_PAYMENT", resp.data.monthly_payment);
        localStorage.setItem("ORG_STATUS", resp.data.client_status);
        const { token, permissions } = resp.data;
        dispatch(onSignInSuccess(token));

        //set the permission
        dispatch(setPermission(permissions));
        if (resp.data.user) {
          const allowedPermissions = extractPermissions(permissions);
          allowedPermissions.push("admin");
          allowedPermissions.push("user");
          if (resp.data.user.id === 1) {
            allowedPermissions.push("superadmin");
          }

          dispatch(
            setUser(
              mutateUser(resp.data.user, allowedPermissions) || {
                avatar: "",
                userName: resp.data.user.first_name,
                authority: ["ADMIN"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
        userId: errors?.response?.data?.user_id || "",
        stage: errors?.response?.data?.stage || "",
      };
    }
  };
  const mutateUser = function (user, allowedPermissions) {
    return {
      ...user,
      avatar: user.avatar || "",
      userName: user.first_name + " " + user.last_name,
      authority: allowedPermissions,
      email: user.email,
    };
  };

  const mutateDonor = function (user) {
    return {
      ...user,
      avatar: "",
      userName: user.username,
      email: user.email,
    };
  };

  const handleSignOut = () => {
    //clear the intercom
    shutdownIntercom();
    // Reboot Intercom in anonymous visitor mode
    bootIntercom();
    localStorage.clear();
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    try {
      await apiSignOut();
      handleSignOut();
    } catch (errors) {
      handleSignOut();
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    DonorSign,
    signOut,
  };
}

export default useAuth;
