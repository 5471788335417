import { createSlice, current } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'getDonorDetails/state',
    initialState: {
        restoreConfirmation: false,
        deletePaymentMethodDialog: false,
        editPaymentMethodDialog: false,
        editPayPalDialog: false,
        addNoteDialog: false,
        editNote: false,
        editCustomerDetailDialog: false,
        deleteConfirmation: false,
        selectedCard: {},
        selectedSponsorship: '',
        sortedColumn: () => {},
        entityState:""
    },
    reducers: {
        openEditNote: (state) => {
            state.editNote = true
        },
        closeEditNote: (state) => {
            state.editNote = false
        },
        openDeletePaymentMethodDialog: (state) => {
            state.deletePaymentMethodDialog = true
        },
        closeDeletePaymentMethodDialog: (state) => {
            state.deletePaymentMethodDialog = false
        },
        openEditPaymentMethodDialog: (state) => {
            state.editPaymentMethodDialog = true
        },
        openEditPayPalDialog: (state) => {
            state.editPayPalDialog = true
        },
        closeEditPaymentMethodDialog: (state) => {
            state.editPaymentMethodDialog = false
        },
        closeEditPayPalDialog: (state) => {
            state.editPayPalDialog = false
        },
        openAddNoteDialog: (state) => {
            state.addNoteDialog = true
        },
        closeAddNoteDialog: (state) => {
            state.addNoteDialog = false
        },
        openEditCustomerDetailDialog: (state) => {
            state.editCustomerDetailDialog = true
        },
        closeEditCustomerDetailDialog: (state) => {
            state.editCustomerDetailDialog = false
        },
        updateSelectedCard: (state,action) => {
            state.selectedCard = action.payload
        },
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        toggleRestoreConfirmation: (state, action) => {
            state.restoreConfirmation = action.payload;
        },
        setSortedColumn: (state, action) => {
            state.sortedColumn = action.payload
        },
        setSelectedSponsorship: (state, action) => {
            state.selectedSponsorship = action.payload
        },
        setEntityData: (state, action) => {
            const currentState = current(state);
            return {
              ...currentState,
              entityState: action.payload,
            };
          },
    },
})

export const { 
    openEditNote,
    closeEditNote,
    openDeletePaymentMethodDialog,
    closeDeletePaymentMethodDialog,
    openAddNoteDialog,
    closeAddNoteDialog,
    openEditPaymentMethodDialog,
    openEditPayPalDialog,
    closeEditPayPalDialog,
    closeEditPaymentMethodDialog,
    openEditCustomerDetailDialog,
    closeEditCustomerDetailDialog,
    toggleRestoreConfirmation,
    updateSelectedCard,
    toggleDeleteConfirmation, 
    setSortedColumn,
    setSelectedSponsorship,
    setEntityData
} = stateSlice.actions

export default stateSlice.reducer
