import React, { useState, forwardRef, useCallback } from 'react';
import { HiEye, HiTrash } from 'react-icons/hi';
import { Dialog } from "components/ui";
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const ImageList = (props) => {
  const [viewOpen, setViewOpen] = useState(false)

	const { imgUrl, setState } = props

  const onViewOpen = () => {
    setViewOpen(true)
  }

  const onDialogClose = () => {
    setViewOpen(false)
  }

  const onDeleteConfirmation = () => {
    setState(prevState => ({
      ...prevState,
      hasFile: false,
      imgUrl: "",
      file: null,
    }));
  }

	return (
		<>
      <div className="group relative rounded border p-2 flex">
        <iframe 
          src={imgUrl}
          className="rounded max-h-[70px] max-w-[70px]"
          title={`Preview`}>
        </iframe>
        <div className="absolute inset-2 bg-gray-900/[.7] group-hover:flex hidden text-xl items-center justify-center">
          <span
            onClick={() => onViewOpen(imgUrl)}
            className="text-gray-100 hover:text-gray-300 cursor-pointer p-1.5"
          >
            <HiEye />
          </span>
          <span
            onClick={() => onDeleteConfirmation(imgUrl)}
            className="text-gray-100 hover:text-gray-300 cursor-pointer p-1.5"
          >
            <HiTrash />
          </span>
        </div>
        <Dialog
          isOpen={viewOpen}
          onClose={onDialogClose}
          onRequestClose={onDialogClose}
        >
          <h5 className="mb-4">Upload</h5>
          <iframe 
              src={imgUrl} 
              width="100%" 
              height="500px"
              title={`Upload`}>
            </iframe>
        </Dialog>
      </div>
		</>
	)
}


const RichTextEditor = forwardRef((props, ref) => {
  const { state, setState } = props;

  const imageHandler = useCallback(() => {
    let input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  
    input.onchange = () => {
      let file = input.files[0];

      setState(prevState => ({
        ...prevState,
        hasFile: true,
        imgUrl: URL.createObjectURL(file),
        file: file,
      }));
    };
  }, [setState])
  
  const modules = {
    toolbar: {
      handlers: {
        image: imageHandler,
      },
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['image', 'video'], // Add the image and video options
      ],
    },
  };
  
  const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
    'header', 'list', 'bullet', 'script', 'indent', 'direction',
    'size', 'color', 'background', 'font', 'align', 'clean',
    'image', 'video', // Include the image and video formats
  ];

  return (
   <div className="rich-text-editor">
      <ReactQuill ref={ref} {...props} modules={modules} formats={formats} />
      {state && state?.hasFile && 
        <div className="flex items-center gap-2 pt-2">
          <ImageList 
            imgUrl={state.imgUrl}
            setState={setState}
          />
          <div>
            <div className="flex gap-1.5 items-end mb-2">
              <p className="font-semibold">A file has been selected</p>
            </div>
          </div>
        </div>
      }
    </div>
  );
});

export default RichTextEditor;